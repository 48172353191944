<template>
  <v-card
    rounded="lg"
    color="transparent"
    height="100%"
    class="d-flex flex-column"
    >
    <v-card-title
      class="py-1 primary white--text lower-opacity"
      >
      Actividades
    </v-card-title>

    <v-card-text
      style="max-height: 300px; overflow: auto"
      class="pt-6 lower-background-opacity grow"
      >
      <v-row>
        <v-col
          cols="4"
          >
          <v-autocomplete
            outlined
            :items="workTypes"
            label="Tipo de trabajo"
            :value="rollout.workType"
            readonly
            hide-details
            dense
            style="background-color: white"
            class="mb-3"
            append-icon=""
            ></v-autocomplete>
        </v-col>

        <v-col
          v-if="rollout.workType == 'TSS'"
          cols="4"
          >
          <v-autocomplete
            outlined
            label="Tecnología"
            :items="['4G', '5G']"
            :value="rollout.technology"
            readonly
            hide-details
            dense
            multiple
            chips
            small-chips
            style="background-color: white"
            class="mb-3"
            append-icon=""
            ></v-autocomplete>
        </v-col>
      </v-row>
      <template
        v-if="!(rollout.workType == 'TSS')"
        v-for="activity in rollout.nokiaActivities"
        >
        <v-row>
          <v-col
            cols="2"
            class="pr-0 pb-0"
            >
            <v-text-field
              outlined
              readonly
              label="Tecnología"
              :value="activity.technology"
              dense
              hide-details
              style="background-color: white"
              class="mb-3"
              ></v-text-field>
          </v-col>

          <v-col
            cols="6"
            class="pr-0 pb-0"
            >
            <v-text-field
              outlined
              label="Escena"
              :value="activity.scene"
              readonly
              dense
              hide-details
              mandatory
              style="background-color: white"
              class="mb-3"
              :rules="[
              v => !!v || 'Campo obligatorio',
              ]"
              ></v-text-field>
          </v-col>

          <v-col
            cols="2"
            class="pr-0 pb-0"
            >
            <v-text-field
              outlined
              label="SMP"
              :value="activity.smp"
              readonly
              dense
              style="background-color: white"
              hide-details
              class="mb-3"
              ></v-text-field>
          </v-col>

          <v-col
            cols="2"
            class="pb-0"
            >
            <v-autocomplete
              outlined
              label="TSS"
              :items="[{ text: 'Sí', value: true }, { text: 'No', value: false }]"
              :value="activity.tss"
              readonly
              dense
              hide-details
              style="background-color: white"
              class="mb-3"
              append-icon=""
              ></v-autocomplete>
          </v-col>
        </v-row>
      </template>
    </v-card-text>

    <v-card-actions
      class="lower-background-opacity"
      style="gap: 8px"
      >
      <v-spacer></v-spacer>
      <close
        v-if="currentUser && (['shibui', 'rollout', 'management'].includes(currentUser.currentRoleName)) && rollout && rollout.nokiaActivities && rollout.nokiaActivities.length"
        :disabled="!!rollout.nokiaActivities[0].closed"
        activity="nokia"
        :id="rollout.id"
        @reload="reload"
        ></close>

      <done
        :key="band"
        :rollout="rollout"
        @reload="reload"
        ></done>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

import Done from '@/components/sites/main/tasks/Done'
import Close from '@/components/sites/main/activities/Close'

export default {
  data: () => ({
    scenes: {
      '4G': [
        "Implementation (1xRAT)",
        "Implementation (2xRAT)",
        "Implementation 1xBAND expansion (RF or RRU and all sectors). Add band to existing site or increase MIMO",
        "Implementation 1xBAND upgrade (RF or RRU and all sectors). Add band to existing site or increase MIMO",
        "Implementation 2xBAND expansion  (RF or RRU and all sectors). Add band to existing site or increase MIMO",
        "Implementation 2xBAND upgrade  (RF or RRU and all sectors). Add band to existing site or increase MIMO",
        "Implementation 3xBAND expansion  (RF or RRU and all sectors). Add band to existing site or increase MIMO",
        "Implementation 3xBAND upgrade  (RF or RRU and all sectors). Add band to existing site or increase MIMO",
        "Implementation expansion  (RF or RRU and all sectors). Add band to existing site or increase MIMO",
      ],
      '5G': [
        "Implementation (1xRAT) (RF ICI+ADD2)",
        "Implementation (1xRAT) (RF+BB ICI+ADD2)",
        "Implementation (2xRAT) (RF+BB ICI+ADD2)",
      ]
    },
    workTypes: ['TSS', 'Implementación'],
    band: 0,
  }),

  props: {
    rollout: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters(['currentUser'])
  },

  methods: {
    reload () {
      this.band += 1
      this.$emit('reload', this.rollout.id)
    }
  },

  components: { Done, Close }
}
</script>
